const ChargingStation = {
  name: "ChargingStation",
  unitNumberPrefixes: [
     // Too wide unit number prefix, but Karte uses it for ChargingStation
     "C",

    // old unit number prefix
    "C33", // mass production
    "C31", // prototype

    // new unit number prefix
    "CS34", // mass production and prototype
  ],
};

export const ChargingStation_VERSIONS = [
  ChargingStation,
];

export const ChargingStation_ALL = ChargingStation
