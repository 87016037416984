const Lexx500_V4 = {
  name: "Lexx500 V4",
  unitNumberPrefixes: ["V4"],
};

const Lexx500_V5 = {
  name: "Lexx500 V5",
  unitNumberPrefixes: ["V5"],
};

const Lexx500_V6 = {
  name: "Lexx500 V6",
  unitNumberPrefixes: ["V6"],
};

const Lexx500_V7 = {
  name: "Lexx500 V7",
  unitNumberPrefixes: ["V7"],
};

export const Lexx500_VERSIONS = [
  Lexx500_V4,
  Lexx500_V5,
  Lexx500_V6,
  Lexx500_V7,
];

export const Lexx500_ALL = {
  name: "Lexx500",
  unitNumberPrefixes: [
     // Too wide unit number prefix, but Karte uses it for Lexx500
    "V",

    ...Lexx500_V4.unitNumberPrefixes,
    ...Lexx500_V5.unitNumberPrefixes,
    ...Lexx500_V6.unitNumberPrefixes,
    ...Lexx500_V7.unitNumberPrefixes,
  ],
};

