import {
  ChargingStation_ALL,
  ChargingStation_VERSIONS,
} from "./ChargingStation";
import { Lexx500_ALL, Lexx500_VERSIONS } from "./Lexx500";
import { LexxHub_ALL, LexxHub_VERSIONS } from "./LexxHub";
import {
  LexxTug_H_ALL,
  LexxTug_H_VERSIONS,
  LexxTug_L_ALL,
  LexxTug_L_VERSIONS,
} from "./LexxTug";

export const PRODUCT_SUMMARIES = [
  Lexx500_ALL,
  ChargingStation_ALL,
  LexxTug_H_ALL,
  LexxTug_L_ALL,
  LexxHub_ALL,
];

export const PRODUCTS = [
  Lexx500_ALL,
  ...Lexx500_VERSIONS,

  ...ChargingStation_VERSIONS,

  LexxTug_H_ALL,
  ...LexxTug_H_VERSIONS,

  LexxTug_L_ALL,
  ...LexxTug_L_VERSIONS,

  LexxHub_ALL,
  ...LexxHub_VERSIONS,
];
