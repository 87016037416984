const LexxHub_V1 = {
  name: "LexxHub V1",
  unitNumberPrefixes: ["KNH"],
};

const LexxHub_V2 = {
  name: "LexxHub V2",
  unitNumberPrefixes: ["HB20"],
};

export const LexxHub_VERSIONS = [
  LexxHub_V1,
  LexxHub_V2,
];

export const LexxHub_ALL = {
  name: "LexxHub",
  unitNumberPrefixes: [
    ...LexxHub_V1.unitNumberPrefixes,
    ...LexxHub_V2.unitNumberPrefixes,
  ],
};
