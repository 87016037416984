/**
 * LexxTug H
 */
const LexxTug_H_V6 = {
  name: "LexxTug H V6",
  unitNumberPrefixes: [
    // old unit number prefix
    "TG10", // mass production

    // new unit number prefix
    "TGH20", // mass production and prototype
  ],
};

const LexxTug_H_V7 = {
  name: "LexxTug H V7",
  unitNumberPrefixes: [
    // old unit number prefix
    "TG11", // mass production

    // new unit number prefix
    "TGH21", // mass production and prototype
  ],
};

export const LexxTug_H_VERSIONS = [
  LexxTug_H_V6,
  LexxTug_H_V7,
];

export const LexxTug_H_ALL = {
  name: "LexxTug H",
  unitNumberPrefixes: [
    ...LexxTug_H_V6.unitNumberPrefixes,
    ...LexxTug_H_V7.unitNumberPrefixes,
  ],
};

/**
 * LexxTug L
 */
const LexxTug_L_V6 = {
  name: "LexxTug L V6",
  unitNumberPrefixes: [
    // old unit number prefix
    "TL10", // mass production

    // new unit number prefix
    "TGL20", // mass production and prototype
  ],
};

const LexxTug_L_V7 = {
  name: "LexxTug L V7",
  unitNumberPrefixes: [
    // old unit number prefix
    "TL11", // mass production

    // new unit number prefix
    "TGL21", // mass production and prototype
  ],
};

export const LexxTug_L_VERSIONS = [
  LexxTug_L_V6,
  LexxTug_L_V7,
];

export const LexxTug_L_ALL = {
  name: "LexxTug L",
  unitNumberPrefixes: [
    ...LexxTug_L_V6.unitNumberPrefixes,
    ...LexxTug_L_V7.unitNumberPrefixes,
  ],
};
